import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"
import SEO from "../components/SEO"
import Icon from "../components/Icon"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Page Not found" description="Page not found" />
    <Heading title="Not Found" />
    <div className="col-12 text-center">
      <p>Sorry, the requested URL can't be found</p>
      <Link to="/">
        <Button variant="primary" bsPrefix="smg">
          Home <Icon prefix="fas" name="home" />
        </Button>
      </Link>
      <p></p>
    </div>
  </Layout>
)

export default NotFoundPage
